import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Routes } from "react-router-dom";
import { getProfile } from "../api/profile";
import Banner from "../components/Banner";
import FooterComponent from "../components/FooterComponent";
import { LazyLoadProgressBar } from "../components/lazyLoadProgressBar";
import MainNav from "../components/MainNav";
import Navbar from "../components/Navbar";
import { setLoggedIn } from "../store/actions/authAction/authActionCreators";
import { getProfileInfo } from "../store/actions/profile/profileActionCreators";
import { useAppDispatch } from "../store/reduxHooks";
import { authRoutes } from "./auth.routes";

function Routing() {
  const dispatch = useAppDispatch();
  const [isExpand, setIsExpand] = useState(false);

  const toggleMenu = () => {
    setIsExpand(!isExpand);
  };

  const getUserProfile = useCallback(async () => {
    const [response] = await getProfile();

    if (response) {
      dispatch(getProfileInfo(response.data));
      dispatch(setLoggedIn(true));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserProfile();
  }, [getUserProfile]);

  if (window.location.href.includes("/member")) {
    return (
      <Suspense fallback={<LazyLoadProgressBar />}>
        <Routes>
          {authRoutes}
        </Routes>
      </Suspense>
    )
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rastriya Swatantra Party</title>
      </Helmet>
      <div className="home-page">
        <div className="home-wrapper mh-100">
          <MainNav />
          <Navbar toggleMenu={toggleMenu} isExpand={isExpand}/>
          <Banner/>
          <Suspense fallback={<LazyLoadProgressBar />}>
            <Routes>
              {authRoutes}
            </Routes>
          </Suspense>
          <FooterComponent />
        </div>
      </div>
    </>
  );
}

export default Routing;
