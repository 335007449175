import { authReducer } from "./reducers/authReducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { profileReducer} from "./reducers/profileReducer"
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { donationReducer } from "./reducers/donationReducer";

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  authReducer: authReducer,
  profileReducer: profileReducer,
  donationReducer: donationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
