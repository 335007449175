import { Button, Menu, Dropdown } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { appLocales } from '../common/language';
import { NavLink, useNavigate } from 'react-router-dom';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/reduxHooks';
import { getAboutPages } from '../api/public';
import { errorParser, openErrorNotification } from '../util/common.util';
import { DonationIcon } from './icons/DonationIcon';

interface IProps {
  toggleMenu: () => void;
  isExpand: boolean;
}

function Navbar({ toggleMenu, isExpand }: IProps) {
  const { isLoggedIn } = useAppSelector(state => state.authReducer);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [aboutPages, setAboutPages] = useState<any[]>([]);

  const [currentLocale, setCurrentLocale] = useState<string>(localStorage.getItem('i18nextLng') as string);

  const getPages = async () => {
    const [response, error] = await getAboutPages();
    if (error) {
      openErrorNotification(errorParser(error), t("CommonMessages.error"));
    }
    if (response) {
      setAboutPages(response.data.results);
    }
  }

  const mobileDropDownClickHandler = () => {
    if (window.innerWidth < 950) {
      toggleMenu()
    }
  }

  const aboutMenu = (
    <Menu
      items={
        [...aboutPages.sort((a: any, b: any) => a.position - b.position).map(page => {
          return {
            key: page.slug,
            label: currentLocale === 'ne' ? page.titleNp || page.titleEn : page.titleEn,
            onClick: () => {
              navigate(`/about/${page.slug}`)
              mobileDropDownClickHandler()
            }
          }
        }),
        {
          key: '13',
          label: (< NavLink to="/executive-members" > {t('HomePage.executiveMembers')}</NavLink>),
          onClick: mobileDropDownClickHandler
        },
        {
          key: '14',
          label: (< NavLink to="/rspmanifesto2079" > {t('Manifesto.pageHeader')}</NavLink>),
          onClick: mobileDropDownClickHandler
        }
        ]
      }
    />
  );

  useEffect(() => {
    getPages();
  }, []);

  const othersMenu = (
    <Menu
      items={[
        {
          key: '6',
          label: (<NavLink to="/event">
            {t('Event.pageHeader')}
          </NavLink>),
          onClick: mobileDropDownClickHandler
        },
        {
          key: '7',
          label: (<NavLink to="/gallery">
            {t('HomePage.gallery')}
          </NavLink>),
          onClick: mobileDropDownClickHandler
        },
        {
          key: '8',
          label: (<NavLink to="/video-gallery">
            {t('VideoGallery.pageHeader')}
          </NavLink>),
          onClick: mobileDropDownClickHandler
        }
      ]} />
  )

  const selectLocale = ({ key }: any) => {
    setCurrentLocale(key);
  };

  return (
    <div className="navbar">
      <Header className="d-flex w-100 headers">
        <div className="container d-flex w-100">
          <div className="header-main">
            <div className="w-100">
              <div className="mobile-devices">
                <Button onClick={toggleMenu}>
                  <MenuOutlined />
                </Button>
              </div>

              <Menu mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1">
                  <NavLink to="/">
                    {t('HomePage.home')}
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="2">
                  <Dropdown overlay={aboutMenu} trigger={['click']}>
                    <a onClick={e => e.preventDefault()} href="/about-us">
                      {t('HomePage.aboutUs')} <DownOutlined />
                    </a>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="3">
                  <NavLink to="/blog">
                    {t('HomePage.blogs')}
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="4">
                  <Dropdown overlay={othersMenu} trigger={['click']}>
                    <a onClick={e => e.preventDefault()} href="/contact-us">
                      {t('HomePage.media')} <DownOutlined />
                    </a>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="5">
                  <NavLink to="/transparency">
                    {t('HomePage.transparency')}
                  </NavLink>
                </Menu.Item>
              </Menu>
            </div>
          </div>

          <div className="header-right">
            <Menu
              className="text-center"
              onClick={selectLocale}
              mode="horizontal"
              defaultSelectedKeys={[currentLocale]}
            >
              {appLocales.map((lang) => (
                <Menu.Item
                  style={{ textAlign: 'right' }}
                  key={lang.key}
                  className="language-switcher"
                  onClick={() => { i18n.changeLanguage(lang.key) }}
                >
                  {lang.label}
                </Menu.Item>
              ))}
            </Menu>
            <Button type="primary" icon={<DonationIcon />} className="ml-10 donation-btn" onClick={() => navigate('/donation')}>
              {t('HomePage.donation')}
            </Button>
            {!isLoggedIn && window.location.pathname !== '/login' && (
              <Button
                onClick={() => navigate('/login')}
                type="primary"
                style={{ marginLeft: '10px' }}
                size="large"
              >
                {t('HomePage.login')}
              </Button>
            )}
          </div>
        </div>
      </Header >
      <div className="collapse-menu-wrapper">
        <ul
          className={`collapse-menu ${isExpand ? 'collapse-open' : 'collapse-close'
            } `}
        >
          <li onClick={toggleMenu}>
            <NavLink to="/">
              {t('HomePage.home')}
            </NavLink>
          </li>
          <li>
            <Dropdown overlay={aboutMenu} trigger={['click']}>
              <a onClick={e => e.preventDefault()} href="/about-us">
                {t('HomePage.aboutUs')} <DownOutlined />
              </a>
            </Dropdown>
          </li>
          <li onClick={toggleMenu}>
            <NavLink to="/blog">
              {t('HomePage.blogs')}
            </NavLink>
          </li>
          <li>
            <Dropdown overlay={othersMenu} trigger={['click']}>
              <a onClick={e => e.preventDefault()} href="/contact-us">
                {t('HomePage.media')} <DownOutlined />
              </a>
            </Dropdown>
          </li>
          <li onClick={toggleMenu}>
            <NavLink to="/transparency">
              {t('HomePage.transparency')}
            </NavLink>
          </li>
        </ul>
      </div>
    </div >
  );
}

export default Navbar;
