import React from 'react';
import { Col, Layout, Row, Typography, Divider } from 'antd';
import {
  EnvironmentOutlined,
  FacebookFilled,
  InstagramOutlined,
  MailOutlined,
  PhoneOutlined,
  TwitterCircleFilled,
  YoutubeFilled,
} from '@ant-design/icons';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { useTranslation } from 'react-i18next';
import { TiktokIcon } from './icons/Tiktok';
import QR from "../assets/images/fonepayQr.png"
import { PlayStoreIcon } from './icons/PlayStoreIcon';
import { AppStoreIcon } from './icons/AppStoreIcon';
import { FonepayIcon } from './icons/FonepayIcon';
import { RSPIcon } from './icons/RSPIcon';
import DonationFooter from '../assets/images/donationFooter.png'

const { Footer } = Layout;
const facebookLink = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FRastriyaswatantraparty%2F&tabs=timeline&width=320&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=210807007386227"

function FooterComponent() {
  const { t } = useTranslation();

  return (
    <Footer className="footer-wrapper">
      <div className="footer-social">
        <div className="container text-white">
          <h2>
            {t('Footer.followTitle')}
          </h2>
          <Row gutter={[16, 16]}>
            <Col md={8} sm={24} xs={24}>
              <div className="embbed">
                <iframe title='facebook'
                  src={facebookLink}
                  height="500"
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>
            </Col>
            <Col md={8} sm={12} xs={24} className="order-3">
              <div className="embbed twitter">
                <div className="centerContent">
                  <div className="selfCenter spaceBetween">
                    <TwitterTimelineEmbed
                      options={{
                        height: 350,
                      }}
                      screenName="Rastriya Swatantra Party"
                      sourceType="profile"
                      url="https://twitter.com/party_swatantra"
                      theme="dark"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} sm={12} xs={24} className="order-2">
              <div className="embbed youtube">
                <iframe
                  src="https://www.youtube.com/embed/khUIb9rZJKQ"
                  title="YouTube video player"
                  frameBorder="0"
                  height="350"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-contact">
        <div className="container text-center text-white">
          <Row gutter={[12, 12]} className="footer-row">
            <Col xs={24} sm={12} md={8}>
              <div className="footer-col">
                <Typography.Title
                  level={4}
                  className="text-white text-left contact-title"
                >
                  {t('Footer.appName')}
                </Typography.Title>
                <ul className="footer-details mb-10">
                  <li>
                    <div className="icon">
                      <EnvironmentOutlined className="icon" />
                    </div>
                    <Typography.Text className="text-white text-left">
                      {t('Footer.address')}
                    </Typography.Text>
                  </li>
                  <li>
                    <div className="icon">
                      <MailOutlined className="icon" />
                    </div>
                    <Typography.Text className="text-white">
                      {t('Footer.email')}
                    </Typography.Text>
                  </li>
                  <li>
                    <div className="icon">
                      <PhoneOutlined rotate={90} className="icon" />
                    </div>
                    <Typography.Text className="text-white">
                      {t('Footer.phone')}
                    </Typography.Text>
                  </li>
                </ul>
                <p className="text-left text-white mb-10 mt-10 follow-title">
                  {t('Footer.followTitle')}
                </p>
                <ul className="social-links list-none p-0 text-left">
                  <li>
                    <a
                      href="https://www.facebook.com/Rastriyaswatantraparty/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookFilled />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/party_swatantra"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterCircleFilled />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/rastriya_swatantra_party/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramOutlined />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UChDR9Xc7axyyf5zYQN3OSVQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <YoutubeFilled />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@rastriya_swatantra_party"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TiktokIcon className="tiktok" />
                    </a>
                  </li>
                </ul>
                <div className="mt-20 donation-footer">
                  <img src={DonationFooter} alt="" />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div className="footer-col text-center text-white">
                <div className="we-accept">
                  <Typography.Title level={5} className="text-white">
                    {t("Donation.support")}
                  </Typography.Title>
                  <FonepayIcon />
                </div>
                <img src={QR} alt="QRCode" loading='lazy' className='qr-image' />
                <div className="qr-details">
                  <p><strong>RASTRIYA SWATANTRA PARTY</strong></p>
                  <p>{t("Donation.bankName")}: Nepal Bank Limited</p>
                  <p>{t("Donation.accNo")}: 28700107308118000001</p>
                  <p>{t("Donation.branchName")}: Nayabazar Branch</p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} className="text-center">
              <div className="footer-col text-right footer-app-links">
                <RSPIcon className="footer-icon" />
                <a href="https://play.google.com/store/apps/details?id=com.rsp.rspnepal" target="_blank" rel="noopener noreferrer" >
                  <PlayStoreIcon />
                </a>
                <a href="https://apps.apple.com/qa/app/rastriya-swatantra-party/id1640688510" target="_blank" rel="noopener noreferrer" >
                  <AppStoreIcon />
                </a>
              </div>

            </Col>
          </Row>
        </div>
        <Divider />
        <div className="container w-100 links">
          <div className="left text-white">
            <p>&copy; {t("Footer.rightsReserved")}</p>
          </div>
          <div className="right text-white">
            <a href="/volunteer-form" target="__blank">{t("HomePage.volunteer")}</a>
            <a href="/downloads" target="__blank">{t("HomePage.downloads")}</a>
            <a href="/about/privacy-and-policy" target="__blank">{t("Footer.privacyPolicy")}</a>
            <a href="/contact-us">{t("Footer.contact")}</a>
          </div>
        </div>
      </div>
    </Footer>
  );
}

export default FooterComponent;
