import { ReactNode, lazy } from "react";
import { Route } from "react-router";
import { IRouteItem } from "../interfaces";

export const authRouteList: IRouteItem[] = [
  {
    name: "Login",
    path: "/login",
    LazyComponent: lazy(() => import("../pages/Login/Login")),
  },
  {
    name: "Register",
    path: "/register",
    LazyComponent: lazy(() => import("../pages/Register/Register")),
  },
  {
    name: "Voter Form",
    path: "/voter-form",
    LazyComponent: lazy(() => import("../pages/Register/Register")),
  },
  {
    name: "Profile",
    path: "/public-profile",
    LazyComponent: lazy(() => import("../pages/Profile/Profile")),
  },
  {
    name: "Change Password",
    path: "/change-password",
    LazyComponent: lazy(() => import("../pages/ChangePassword/ChangePassword")),
  },
  {
    name: "Home Page",
    path: "/",
    LazyComponent: lazy(() => import("../pages/Home/Home")),
  },
  {
    name: "OTP Page",
    path: "/otp-verify",
    LazyComponent: lazy(() => import("../pages/OtpPage/OtpPage")),
  },
  {
    name: "Set Password",
    path: "/set-password",
    LazyComponent: lazy(() => import("../pages/SetPassword/SetPassword")),
  },
  {
    name: "About Us",
    path: "/about/:slug",
    LazyComponent: lazy(() => import("../pages/AboutUs/AboutUs")),
  },
  {
    name: "Contact Us",
    path: "/contact-us",
    LazyComponent: lazy(() => import("../pages/ContactUs/ContactUs")),
  },
  {
    name: "Downloads",
    path: "/downloads",
    LazyComponent: lazy(() => import("../pages/Downloads/Downloads")),
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    LazyComponent: lazy(() => import("../pages/ForgotPassword/ForgotPassword")),
  },
  {
    name: "Reset OTP",
    path: "/reset-otp",
    LazyComponent: lazy(() => import("../pages/ResetOtp/ResetOtp")),
  },
  {
    name: "Reset Password",
    path: "/reset",
    LazyComponent: lazy(() => import("../pages/ResetPassword/ResetPassword")),
  },
  {
    name: "Member Profile",
    path: "/member/:id",
    LazyComponent: lazy(() => import("../pages/MemberProfile/MemberProfile")),
  },
  {
    name: "Executive Members",
    path: "/executive-members",
    LazyComponent: lazy(() => import("../pages/ExecutiveMembers/ExecutiveMembers")),
  },
  {
    name: "Blogs",
    path: "/blog",
    LazyComponent: lazy(() => import("../pages/Blogs/Blogs")),
  },
  {
    name: "Blog",
    path: "/blog/:slug",
    LazyComponent: lazy(() => import("../pages/Blog/Blog")),
  },
  {
    name: "Events",
    path: "/event",
    LazyComponent: lazy(() => import("../pages/Events/Events")),
  },
  {
    name: "Event",
    path: "/event/:slug",
    LazyComponent: lazy(() => import("../pages/Event/Event")),
  },
  {
    name: "Donation",
    path: "/donation",
    LazyComponent: lazy(() => import("../pages/Donation/Donation")),
  },
  {
    name: "Donation Success",
    path: "/donation/success",
    LazyComponent: lazy(() => import("../pages/DonationSuccessPage/DonationSuccessPage")),
  },
  {
    name: "Donation Failure",
    path: "/donation/failure",
    LazyComponent: lazy(() => import("../pages/DonationSuccessPage/DonationSuccessPage")),
  },
  {
    name: "Video Gallery",
    path: "/video-gallery",
    LazyComponent: lazy(() => import("../pages/VideoGallery/VideoGallery")),
  },
  {
    name: "Galleries",
    path: "/gallery",
    LazyComponent: lazy(() => import("../pages/Galleries/Galleries")),
  },
  {
    name: "Galleries",
    path: "/gallery/:slug",
    LazyComponent: lazy(() => import("../pages/Gallery/Gallery")),
  },
  {
    name: "Page not found",
    path: "/*",
    LazyComponent: lazy(() => import("../pages/Error/Pagenotfound")),
  },
  {
    name: "Email Verification",
    path: "/email-verification",
    LazyComponent: lazy(() => import("../pages/EmailVerified/EmailVerified")),
  },
  {
    name: "Volunteer Form",
    path: "/volunteer-form",
    LazyComponent: lazy(() => import("../pages/VolunteerForm/VolunteerForm")),
  },
  {
    name: "Candidate Form",
    path: "/candidate-form",
    LazyComponent: lazy(() => import("../pages/CandidateForm/CandidateForm")),
  },
  {
    name: "Candidates",
    path: "/candidates",
    LazyComponent: lazy(() => import("../pages/Candidates/Candidates")),
  },
  {
    name: "Candidates Result",
    path: "/candidates-result",
    LazyComponent: lazy(() => import("../pages/Candidates/Candidates")),
  },
  {
    name: "Direct Result",
    path: "/direct-result",
    LazyComponent: lazy(() => import("../pages/Candidates/Candidates")),
  },
  {
    name: "Parliament Result",
    path: "/parliament-result",
    LazyComponent: lazy(() => import("../pages/Candidates/Candidates")),
  },
  {
    name: "Candidate Info",
    path: "/candidates/:id",
    LazyComponent: lazy(() => import("../pages/Candidate/Candidate")),
  },
  {
    name: "Income/Expense Sheet",
    path: "/transparency",
    LazyComponent: lazy(() => import("../pages/IncomeExpenseSheet/IncomeExpenseSheet")),
  },
  {
    name: "RSP Manifesto",
    path: "/rspmanifesto2079",
    LazyComponent: lazy(() => import("../pages/RSPManifesto/RSPManifesto")),
  },
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route, index: number) =>
    routerDom.push(
      <Route
        {...route}
        key={index}
        element={<route.LazyComponent route={route} currentLocale="ne" />}
      />
    )
  );
  return routerDom;
};

export const authRoutes = parseRoutes(authRouteList);
