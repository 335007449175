import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const DonationSvg = () => (
  <svg width="20" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2" stroke="white" stroke-width="1.8"
      stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17 3V7H21" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22 2L17 7" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M7 10H17M12 15.5C12 16.1667 12 17.5 10.5 17.5C9 17.5 8.5 16.5 8.5 15.5C8.5 14.5 8.5 14 8.5 13.5C8.5 13.5 13.5 13.5 15 13.5C16.5 13.5 16.6664 15.3336 16 16C15.3336 16.6664 14.5 16.5 14 16.5"
      stroke="white" stroke-width="1.8" />
    <path d="M12.5 10C12.8333 11.1667 13 13.5 11 13.5" stroke="white" stroke-width="1.8" />
  </svg>
);

export const DonationIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={DonationSvg} {...props} />;
