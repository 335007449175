import { Spin, Button } from "antd";
import React from "react";
import { ErrorIcon } from "../components/icons/ErrorIcon";

export interface IState {
  hasError: boolean;
  errorInfo: any;
  chunkError: boolean;
}

export class ErrorBoundary extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: {},
      chunkError: true
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err: Error, info: any) {
    console.log(err, info);
    if (err.name === 'ChunkLoadError') {
      window.location.reload();
    }
    return { chunkError: false };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-wrapper">
          {this.state.chunkError ? <Spin size="large" /> :
            <>
              <div className="error-icon">
                <ErrorIcon />
              </div>
              <h1>तपाईंले पछ्याएको लिङ्क सम्भवतः टुटेको छ वा पृष्ठ हटाइएको छ</h1>
              <Button type="primary" onClick={() => window.location.href = "/"}>
                गृहपृष्ठमा जानुहोस्
              </Button>
            </>
          }
        </div>

      );
    }
    return this.props.children;
  }
}
