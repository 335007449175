import { notification } from 'antd';

export const errorParser = (err: any) => {
  if (err?.response?.data) {
    return err?.response?.data?.message;
  } else {
    return 'Something went wrong';
  }
};

export const formErrorParser = (error: any) => {
  const errors = error?.response?.data?.message;
  if (errors && typeof errors !== 'string') {
    return errors[0].errors[0];
  } else {
    return errors
  }
};

export const openErrorNotification = (description: string, message: string) => {
  notification['error']({
    message: message,
    description: description,
    duration: 10,
    className: 'error-notification',
  });
};

export const openSuccessNotification = (
  description: string,
  message: string,
) => {
  notification['success']({
    message: message,
    description: description,
    duration: 10,
    className: 'success-notification',
  });
};

export const isObject = (obj: any) => {
  return obj != null && obj.constructor.name === 'Object';
};

export const scrollToErrorField = (error: any) => {
  if (
    error[0] && error[0]?.errors[0] && error[0]?.name[0]
  ) {
    const element: any = document.querySelector(
      `#cluster-${error[0]?.name[0]}`,
    );
    element && element.scrollIntoView({ block: 'center' });
  }
  if (
    error?.errorFields &&
    error?.errorFields[0] &&
    error?.errorFields[0]?.name
  ) {
    const element: any = document.querySelector(
      `#${error?.errorFields[0]?.name.map((x: any) => x).join('_')}`,
    );
    element && element.scrollIntoView({ block: 'center' });
  }
};

export const handleFormError = async (
  error: any,
  form: any,
  errorMessage: string,
) => {
  const errors = error?.response?.data?.message;
  if (errors && typeof errors !== 'string') {
    errors?.forEach((err: any) => {
      form.setFields([
        {
          name: err.name,
          errors: [err.errors[0]],
        },
      ]);
    });
    const keyName = errors[0].name;
    const element: any = keyName && window.document.getElementById(keyName);
    element && element.scrollIntoView({ block: 'center' });
  } else {
    openErrorNotification(errorParser(error), errorMessage);
  }
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e?.fileList;
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 24 },
    sm: { span: 24 },
  },
};
