import axiosInstance from '../axios/axios';
import { IHttpRes } from '../interfaces';

export const getNotice = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get('/home-notices/latest');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getExecutiveMembers = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get('/executive-members?limit=50');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getMemberDetails = async (
  id: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/profile-from-qr/${id}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getGalleries = async (
  page: number,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/galleries?limit=12&page=${page}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getGallery = async (
  slug: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/galleries/${slug}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDownloads = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get('/downloads');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getVideos = async (
  page: number,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/videos?page=${page}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getPaymentGateways = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get('/payment-gateways');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getBlogCategories = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get('/blog-categories');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const initiatePayment = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/payments', data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const initiatePrabhuPayment = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/payments/prabhu-pay', data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const initiateKhaltiPayment = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/payments/khalti', data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const initiateNabilPayment = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/payments/nabil', data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getBlogCategoriesContent = async (
  slug: string,
  page: number,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/blog-details/${slug}?page=${page}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const verifyEsewaPayment = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      '/payments/confirmation/esewa/web',
      data,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const verifyPrabhuPayment = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      '/payments/confirmation/prabhu-pay',
      data,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const verifyKhaltiPayment = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      '/payments/confirmation/khalti',
      data,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getBlog = async (
  slug: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/blog-contents/${slug}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getEvent = async (
  page: number,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/events?page=${page}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getEventDetails = async (
  slug: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/events/${slug}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAboutPages = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get('/pages');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAboutDetails = async (
  slug: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/pages/${slug}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getCountry = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get('/country');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getProvince = async (
  countryId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/province/${countryId}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDistrict = async (
  provinceId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/district/${provinceId}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getMunicipality = async (
  districtId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/municipality/${districtId}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAllDistricts = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get('/districts');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDashboard = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get('/dashboard/v2');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const postVolunteerForm = async (
  data: any,
  recaptcha: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/volunteers', data, {
      headers: {
        recaptcha: recaptcha,
      },
    });
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const postCandidateForm = async (
  data: any,
  recaptcha: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/candidates', data, {
      headers: {
        recaptcha: recaptcha,
      },
    });
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getFederal = async (
  provinceId: string,
  districtName: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/federal?province=${provinceId}&district=${districtName}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getFederalProvince = async (
  provinceId: string,
  districtName: string,
  federalId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/federal-province?province=${provinceId}&district=${districtName}&federal=${federalId}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getCandidateMunicipality = async (
  provinceId: string,
  districtName: string,
  federalId: string,
  federalProvinceId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/municipalities?province=${provinceId}&district=${districtName}&federal=${federalId}&federalProvince=${federalProvinceId}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getCandidateWard = async (
  provinceId: string,
  districtName: string,
  federalId: string,
  federalProvinceId: string,
  municipalityName: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/wards?province=${provinceId}&district=${districtName}&federal=${federalId}&federalProvince=${federalProvinceId}&municipality=${municipalityName}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDirectCandidates = async (
  provinceId?: string,
  districtName?: string,
  federalId?: string,
  federalProvinceId?: string,
  municipalityName?: string,
  wardId?: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/candidates/verified?province=${provinceId ? provinceId : ''}&district=${
        districtName === 'all' || districtName === undefined ? '' : districtName
      }&federal=${
        federalId === 'all' || federalId === undefined ? '' : federalId
      }&federalProvince=${
        federalProvinceId === 'all' || federalProvinceId === undefined
          ? ''
          : federalProvinceId
      }&municipality=${
        municipalityName === 'all' || municipalityName === undefined
          ? ''
          : municipalityName
      }&wardNo=${wardId === 'all' || wardId === undefined ? '' : wardId}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getVotedDirectCandidates = async (
  provinceId?: string,
  districtName?: string,
  federalId?: string,
  federalProvinceId?: string,
  municipalityName?: string,
  wardId?: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/candidates/verified/voted?province=${
        provinceId ? provinceId : ''
      }&district=${
        districtName === 'all' || districtName === undefined ? '' : districtName
      }&federal=${
        federalId === 'all' || federalId === undefined ? '' : federalId
      }&federalProvince=${
        federalProvinceId === 'all' || federalProvinceId === undefined
          ? ''
          : federalProvinceId
      }&municipality=${
        municipalityName === 'all' || municipalityName === undefined
          ? ''
          : municipalityName
      }&wardNo=${wardId === 'all' || wardId === undefined ? '' : wardId}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDirectCandidatesResult = async (
  provinceId?: string,
  districtName?: string,
  federalId?: string,
  federalProvinceId?: string,
  municipalityName?: string,
  wardId?: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/candidates/verified/result?province=${
        provinceId ? provinceId : ''
      }&district=${
        districtName === 'all' || districtName === undefined ? '' : districtName
      }&federal=${
        federalId === 'all' || federalId === undefined ? '' : federalId
      }&federalProvince=${
        federalProvinceId === 'all' || federalProvinceId === undefined
          ? ''
          : federalProvinceId
      }&municipality=${
        municipalityName === 'all' || municipalityName === undefined
          ? ''
          : municipalityName
      }&wardNo=${wardId === 'all' || wardId === undefined ? '' : wardId}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getCurrentCampaign = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get('/campaigns/current');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getProvinceCandidates = async (
  provinceId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/candidates/province/${provinceId}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getVotedProvinceCandidates = async (
  provinceId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/candidates/voted/${provinceId}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAllCandidates = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get(`/candidates/verified`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const voteProportional = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(`/votes/proportional`, data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const voteFederalDirect = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(`/federal-votes`, data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const voteDirect = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(`/direct-votes`, data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getIndividualCandidate = async (
  candidateId: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/candidates/${candidateId}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDirect = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/candidates/direct`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getVotedDirect = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get(`/candidates/direct/voted`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getResultDirect = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get(`/candidates/direct/result`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getTransparencySheet = async (
  page: number,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/transparencies?page=${page}&limit=1`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDashboardBanner = async (): Promise<
  [IHttpRes<any> | null, any]
> => {
  try {
    const response = await axiosInstance.get(`/dashboard-banners`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getWinnerCandidates = async (
  provinceId?: string,
  districtName?: string,
  federalId?: string,
  federalProvinceId?: string,
  municipalityName?: string,
  wardId?: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/winner-candidates?province=${provinceId ? provinceId : ''}&district=${
        districtName === 'all' || districtName === undefined ? '' : districtName
      }&federal=${
        federalId === 'all' || federalId === undefined ? '' : federalId
      }&federalProvince=${
        federalProvinceId === 'all' || federalProvinceId === undefined
          ? ''
          : federalProvinceId
      }&municipality=${
        municipalityName === 'all' || municipalityName === undefined
          ? ''
          : municipalityName
      }&wardNo=${wardId === 'all' || wardId === undefined ? '' : wardId}`,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};
