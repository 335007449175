import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import BannerImage from '../assets/images/banner.jpg'
import { getDashboardBanner } from '../api/public';
import { errorParser, openErrorNotification } from '../util/common.util';
import { useTranslation } from 'react-i18next';

function Banner() {
  const { t } = useTranslation();
  const location = useLocation();
  const carouselRef: any = React.createRef();
  const [dashboardBanners, setDashboardBanners] = useState([]);

  const getBanners = async () => {
    const [response, error] = await getDashboardBanner();
    if (response) {
      setDashboardBanners(response.data);
    }
    if (error) {
      openErrorNotification(errorParser(error), t('CommonMessages.error'));
    }
  }

  useEffect(() => {
    getBanners();
  }, [])

  return (
    <div
      className="banner"
      style={{
        maxHeight: location.pathname === '/' ? '710px' : '200px',
        minHeight: location.pathname === '/' ? '300px' : '100px'
      }}
    >
      {(dashboardBanners && dashboardBanners?.length > 1) &&
        <div className="left">
          <LeftOutlined onClick={() => carouselRef.current.prev()} />
        </div>
      }
      <Carousel ref={carouselRef}>
        {(dashboardBanners && dashboardBanners?.length > 0) ? dashboardBanners?.map((dashboardBanner: any, index: number) => (
          <div key={index}>
            <img src={dashboardBanner.image} alt="gallery" crossOrigin="anonymous" loading="lazy" className="banner-img" />
          </div>
        )) :
          (
            <div>
              <img src={BannerImage} alt="gallery" crossOrigin="anonymous" loading="lazy" className="banner-img" />
            </div>
          )
        }
      </Carousel>
      {(dashboardBanners && dashboardBanners?.length > 1) &&
        <div className="right">
          <RightOutlined onClick={() => carouselRef.current.next()} />
        </div>
      }
    </div>
  );
}

export default Banner;
