import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { en, ne } from "./locales/index";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    whitelist: ["en", "ne"],
    resources: {
      en: en,
      ne: ne,
    },
    fallbackLng: "en",
    lng: 'ne',
    saveMissing: true,
    debug: false,
    // debug: true,
  });

export default i18n;
