import axios from 'axios';
import { logout } from '../api/auth';

export const StatusCodesList = {
  Success: 1001,
  ValidationError: 1002,
  InternalServerError: 1003,
  NotFound: 1004,
  UnauthorizedAccess: 1005,
  TokenExpired: 1006,
  TooManyTries: 1007,
  ServiceUnAvailable: 1008,
  ThrottleError: 1009,
  Forbidden: 1010,
  IncorrectOldPassword: 1011,
  UserInactive: 1012,
  BadRequest: 1013,
  InvalidCredentials: 1014,
  InvalidRefreshToken: 1015,
  UnsupportedFileType: 1016,
  OtpRequired: 1017,
  defaultItemDeleteError: 1018,
  RefreshTokenExpired: 1019,
};

const baseURL = process.env.REACT_APP_API_BASE_URI;

const axiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

axiosInstance.defaults.headers = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
};

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (err.response.data?.code === StatusCodesList.TokenExpired) {
      // eslint-disable-next-line no-underscore-dangle
      if (!originalConfig._retry) {
        // eslint-disable-next-line no-underscore-dangle
        originalConfig._retry = true;
        try {
          await axiosInstance.post('/refresh', {}, { withCredentials: true });
          return axiosInstance(originalConfig);
        } catch (_error) {
          await logout();
          return Promise.reject(_error);
        }
      }
      return {
        ...originalConfig,
        cancelToken: new axios.CancelToken((cancel) =>
          cancel('Cancel repeated request'),
        ),
      };
    }

    if (err.response.data?.code === StatusCodesList.UnauthorizedAccess) {
      await logout();
    }

    return Promise.reject(err);
  },
);
export default axiosInstance;
