import axiosInstance from '../axios/axios';
import { IHttpRes } from '../interfaces/http.interface';

export const getProfile = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get('/auth/profile');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const editPersonalInfo = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.patch(
      'membership/personal-info',
      data,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
}

export const editContactInfo = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.patch(
      'membership/contact-info',
      data,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const editIdentityInfo = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.patch(
      'membership/identity-info',
      data,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const updateAvatar = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.patch(
      'membership/update-avatar',
      data,
    );
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};
