import { IAuthReducer } from '../../interfaces';
import {
  IS_LOGGED_IN,
  SET_ALERT,
  SET_FORGOT_PASSWORD_INFO,
  SET_OTP_INFO,
  SET_REGISTER_INFO,
  SET_RESET_OTP_INFO,
} from '../actions/authAction/authActions';

const initState: IAuthReducer = {
  isLoggedIn: false,
};

export const authReducer = (state = initState, action: any): IAuthReducer => {
  switch (action.type) {
    case SET_REGISTER_INFO:
      return {
        ...state,
        registerInfo: action.payload,
      };
    case SET_OTP_INFO:
      return {
        ...state,
        otpInfo: action.payload,
      };
    case IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_FORGOT_PASSWORD_INFO:
      return {
        ...state,
        forgotPasswordInfo: action.payload,
      };
    case SET_RESET_OTP_INFO:
      return {
        ...state,
        resetOtpInfo: action.payload,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    default: {
      return state;
    }
  }
};
