
import { Avatar, Dropdown, Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React from 'react';
import {
  TwitterCircleFilled,
  MailOutlined,
  FacebookFilled,
  PhoneOutlined,
  YoutubeFilled,
  UserOutlined,
  InstagramOutlined,
  LogoutOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/reduxHooks';
import { useNavigate, NavLink } from 'react-router-dom';
import { logout } from '../api/auth';
import { errorParser, openErrorNotification } from '../util/common.util';
import { TiktokIcon } from './icons/Tiktok';
import { RSPIcon } from './icons/RSPIcon';
import { RSPTextIcon } from './icons/RSPTextIcon';

function MainNav() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector(state => state.authReducer);
  const { user } = useAppSelector(state => state.profileReducer);

  const onLogout = async () => {
    const [response, error] = await logout();
    if (response) {
      navigate("/login")
    }
    if (error) {
      openErrorNotification(errorParser(error), t('CommonMessages.error'));
    }
  }
  const { t } = useTranslation();

  const menu = (
    <Menu className="config-dropmenu">
      <Menu.Item key="0" className="config-dropmenu">
        <div className="d-flex align-items-center" onClick={() => navigate("/public-profile")}>
          <UserOutlined className="pr-2" />
          {t("LoginPage.profile")}
        </div>
      </Menu.Item>
      <Menu.Item key="1" className="config-dropmenu">
        <div className="d-flex align-items-center" onClick={() => navigate("/change-password")}>
          <LockOutlined className="pr-2" />
          {t("ChangePassword.pageHeader")}
        </div>
      </Menu.Item>
      <Menu.Item key="2" className="config-dropmenu" onClick={onLogout}>
        <div className="d-flex align-items-center">
          <LogoutOutlined className="pr-2" />
          {t("LoginPage.logout")}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="navbar">
      <Header className="d-flex w-100 headers main-nav">
        <div className="container d-flex w-100">
          <div className="top-navbar">
            <NavLink to="/" className="rsp-icon">
              <RSPIcon />
            </NavLink>
            <NavLink to="/" className="rsp-icon">
              <RSPTextIcon className="text-lg" />
            </NavLink>
            <div className="contact-details text-white">
              <div className="links socials">
                <a
                  href="https://www.facebook.com/Rastriyaswatantraparty/"
                  target="_blank"
                  rel="noreferrer"
                  className="fb"
                >
                  <FacebookFilled />
                </a>
                <a
                  href="https://twitter.com/party_swatantra"
                  target="_blank"
                  rel="noreferrer"
                  className="twitter"
                >
                  <TwitterCircleFilled />
                </a>
                <a
                  href="https://www.instagram.com/rastriya_swatantra_party/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  className="insta"
                >
                  <InstagramOutlined />
                </a>
                <a
                  href="https://www.youtube.com/channel/UChDR9Xc7axyyf5zYQN3OSVQ"
                  target="_blank"
                  rel="noreferrer"
                  className="yt"
                >
                  <YoutubeFilled />
                </a>
                <a
                  href="https://www.tiktok.com/@rastriya_swatantra_party"
                  target="_blank"
                  rel="noreferrer"
                  className="yt"
                >
                  <TiktokIcon className="tiktok" />
                </a>
              </div>

              <a className="links phone-links" href="callto:+977 9851360666">
                <PhoneOutlined rotate={90} className="icon" />
                <span className="text text-white">
                  {t('HomePage.phoneNumber')}
                </span>
              </a>
              <a className="links mail-links" href="mailto:info@rspnepal.org">
                <MailOutlined className="icon" />
                <span className="text text-white">
                  {t('HomePage.email')}
                </span>
              </a>
              {isLoggedIn && (
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  overlayClassName="top-nav-dropdown"
                  placement="bottomRight"
                >
                  <span className="user-action">
                    <div className="links logged-in">
                      <Avatar src={user?.avatar} icon={<UserOutlined />} className="ml-10" crossOrigin="use-credentials" />
                    </div>
                  </span>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </Header>
    </div>
  );
}

export default MainNav;
