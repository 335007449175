import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./assets/less/main.less";
import { ErrorBoundary } from "./hoc/ErrorBoundary";
import i18n from "./i18n";
import Routes from "./routes";
import { useEffect } from "react";
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
function App() {
  useEffect(() => {
    require("./assets/less/main.less");
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Routes />
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
