import { SET_REGISTER_INFO, SET_OTP_INFO, IS_LOGGED_IN, SET_FORGOT_PASSWORD_INFO, SET_ALERT, SET_RESET_OTP_INFO } from './authActions';

export const setRegisterInfo = (data: any) => {
  return {
    type: SET_REGISTER_INFO,
    payload: data,
  };
};

export const setOtpInfo = (data: any) => {
  return {
    type: SET_OTP_INFO,
    payload: data,
  };
};

export const setLoggedIn = (data: boolean) => {
  return {
    type: IS_LOGGED_IN,
    payload: data,
  };
}

export const setForgotPasswordInfo = (data: string) => {
  return {
    type: SET_FORGOT_PASSWORD_INFO,
    payload: data,
  };
}

export const setResetOtpInfo = (data: any) => {
  return {
    type: SET_RESET_OTP_INFO,
    payload: data,
  };
}

export const setAlert = (data: any) => {
  return {
    type: SET_ALERT,
    payload: data,
  };
}
