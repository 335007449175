import { IProfileReducer } from '../../interfaces';
import { GET_PROFILE } from '../actions/profile/profileActions';

const initState: IProfileReducer = {};

export const profileReducer = (state = initState, action: any): IProfileReducer => {
  if (action.type === GET_PROFILE) {
    return {
      ...state,
      user: action.payload,
    };
  }
  return state;
};
