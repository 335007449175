import axiosInstance from '../axios/axios';
import {
  IChangePassword,
  ILoginRequest,
  IOtpRequest,
  ISetPasswordRequest,
} from '../interfaces/auth.interface';
import { IHttpRes } from '../interfaces/http.interface';
import { store } from '../store/store';
import { getProfileInfo } from '../store/actions/profile/profileActionCreators'
import { setForgotPasswordInfo, setLoggedIn, setOtpInfo, setRegisterInfo, setResetOtpInfo } from '../store/actions/authAction/authActionCreators';

export const register = async (
  data: any, token: string
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/auth/register', data, {
      headers: {
        'recaptcha': token
      }
    });
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const login = async (
  data: ILoginRequest,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/auth/login', data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const verifyOtp = async (
  data: IOtpRequest,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/verify-otp', data);
    store.dispatch(setRegisterInfo(null))
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const setPassword = async (
  data: ISetPasswordRequest,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.put('/auth/set-password', data);
    store.dispatch(setOtpInfo(null));
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const changePassword = async (
  data: IChangePassword,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.put('/auth/change-password', data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const forgotPassword = async (
  data: string,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.put('/auth/forgot-password', data);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const verifyResetOtp = async (
  data: IOtpRequest,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      '/verify-otp-forgot-password',
      data,
    );
    store.dispatch(setForgotPasswordInfo(''));
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const resetPassword = async (
  data: any,
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.put('/auth/reset-password', data);
    store.dispatch(setResetOtpInfo(null));
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const logout = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post('/logout');
    store.dispatch(getProfileInfo(null));
    store.dispatch(setLoggedIn(false));
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const sendVerificationEmail = async (): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get('/send-verification-email');
    return [response, null];
  } catch (err) {
    return [null, err];
  }
}

export const verifyEmail = async (token: string): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(`/verify-email?token=${token}`);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
}
