import { DELETE_DONATION_INFO, SET_DONATION_INFO } from "../actions/donationAction/donationAction";

export const donationReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case SET_DONATION_INFO:
      return {
        ...state,
        donationInfo: action.payload,
      };
    case DELETE_DONATION_INFO:
      return {
        ...state,
        donationInfo: null,
      };
    default: {
      return state;
    }
  }
}
